import { legacy_createStore as createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  theme: 'light',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState)
export default store

export const logoutAndClearLocalStorage = () => {
  localStorage.clear() // Clear localStorage
  // Redirect to the desired route after clearing localStorage
  window.location.href = '/login' // Change the route path according to your application
}

export function capitalizeFirst(str) {
  return str?.charAt(0).toUpperCase() + str?.slice(1)
}

export function dateTime(mongoDateTime) {
  const date = new Date(mongoDateTime)

  // Format options for date
  const dateFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  // Format options for time
  const timeFormatOptions = {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }

  // Format the date and time using toLocaleString()
  const formattedDate = date.toLocaleDateString('en-GB', dateFormatOptions)
  const formattedTime = date.toLocaleTimeString('en-US', timeFormatOptions)

  // Combine date and time
  const formattedDateTime = `${formattedDate}, ${formattedTime}`

  return formattedDateTime

}

export function todayDate() {
  const today = new Date()

  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }

const t= today.toLocaleDateString('en-GB', options)
return t;
}
